import '../images/favicon.ico'
import '../css/main.css';

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

import 'jquery-mask-plugin';

$(function(){
	window.setTimeout(function() {
		$(".alert-dismissible").fadeTo(1000, 0).slideUp(1000, function() {
			$(this).remove();
		});
	}, 5000);
});